import React, { useEffect, useState } from 'react';
import cart from './cartString';
import cartIcon from './../../assets/Cart.png';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem, fetchCartItems } from '../../reduxStore/cartSlice';
import ibutton from './../../assets/iButton.png';
import like from './../../assets/like (2).png'
import CommonBigOffer from '../../components/bigOffer/CommonBigOffer';
import { deleteFromCart, fetchRefurbishedProducts } from '../../apiEndPoint';
import shopByCategory from '../../components/shopByCategory/shopByCategoryString';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import Slider from 'react-slick';
import CartItems from './CartItems';
import { addOrderThunk } from '../../reduxStore/orderSlice';
import { showSignInAccount } from '../../reduxStore/modalSlice';

// Custom Arrow Components
const PrevArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="absolute left-0 top-[40%] sm:top-[36%] md:top-[31%] xl:top-[35%] 2xl:top-[37%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
            aria-label="Previous"
        >
            <GrLinkPrevious className='' />
        </button>
    );
};

const NextArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="absolute right-0 top-[40%] sm:top-[36%] md:top-[31%] xl:top-[35%] 2xl:top-[37%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
            aria-label="Next"
        >
            <GrLinkNext />
        </button>
    );
};

const Cart = () => {
    const [refurbished, setRefurbished] = useState([]);
    const [confirmAdd, setConfirmAdd] = useState(false);
    const dispatch = useDispatch();
    const cartItemLocal = useSelector((state) => state.cartLocal.cartItems);
    const userId = localStorage.getItem("SignIn id");
    const { items, quantity, status, error } = useSelector((state) => state.cart);

    // Calculate Discounted Price for an Item
    const getDiscountedPrice = (price, discount) => {
        return Math.floor(parseFloat(price || 0) - (parseFloat(price || 0) * (parseFloat(discount || 0) / 100)));
    };

    // Calculate Total Discounted Price
    const calculateTotalDiscountedPrice = (items) => {
        return items.reduce((total, item) => {
            const discountedPrice = getDiscountedPrice(item?.productId?.variation[0]?.price || 0, item?.productId?.variation[0]?.discount || 0);
            const itemPrice = discountedPrice * item.quantity;
            return total + itemPrice;
        }, 0);
    };

    const totalDiscountedPrice = items?.items
        ? calculateTotalDiscountedPrice(items.items) || 0
        : cartItemLocal.reduce((sum, item) => sum + (item.price || 0), 0);

    const gstAmt = Math.round(totalDiscountedPrice * 0.18);
    const grandTotalAmout = totalDiscountedPrice + gstAmt;

    // Fetch Cart Items and Refurbished Products
    useEffect(() => {
        if (!userId) {
            console.log(cartItemLocal);
        } else {
            dispatch(fetchCartItems());
        }
    }, [dispatch]);

    useEffect(() => {
        const fetchRefurbishedProductDate = async () => {
            try {
                const response = await fetchRefurbishedProducts();
                setRefurbished(response);
            } catch (error) {
                console.log(error);
            }
        }
        fetchRefurbishedProductDate();
    }, [items])

    if (status === 'loading') return <p className='min-h-screen'>Loading...</p>;

    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            { breakpoint: 1536, settings: { slidesToShow: 4 } },
            { breakpoint: 1280, settings: { slidesToShow: 4 } },
            { breakpoint: 1024, settings: { slidesToShow: 3 } },
            { breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 640, settings: { slidesToShow: 1 } },
        ],
    };

    const handleAddOrder = async () => {
        if (!userId) {
            dispatch(showSignInAccount());
        } else {
            // Extracting item details and preparing order
            const itemNames = items.items.map((item) => item?.productId?._id);
            const orderItems = items.items.map((item) => ({
                product: item.productId._id,
                quantity: item.quantity,
                price: getDiscountedPrice(item.productId.variation[0].price, item.productId.variation[0].discount),
            }));

            const totalPrice = grandTotalAmout;
            const address = {
                street: 'abs',
                city: 'Surat',
                state: 'Guj',
                country: 'ind',
                zip: '12344',
            };
            const paymentMethod = 'razorpay';

            const razorpayKey = `${process.env.REACT_APP_RAZORPAY_KEY_ID}`;

            const razorpayOptions = {
                key: razorpayKey,
                amount: totalPrice * 100,
                currency: 'INR',
                name: 'Medica Buyer',
                description: 'Order Payment',
                image: 'https://your-logo-url.com/logo.png',

                handler: async function (response) {
                    // Payment success, proceed with order confirmation
                    if (response && response.razorpay_payment_id) {
                        console.log('Transaction ID:', response.razorpay_payment_id); // Log the transaction ID
                        await dispatch(addOrderThunk({
                            items: orderItems,
                            totalPrice,
                            address,
                            paymentMethod,
                        }));

                        // Deleting items from cart after successful payment
                        for (const itemName of itemNames) {
                            try {
                                const deleteResponse = await deleteFromCart(itemName);
                                if (deleteResponse.success) {
                                    console.log(`Successfully deleted item: ${itemName}`);
                                } else {
                                    console.error(`Failed to delete item: ${itemName}`);
                                }
                            } catch (error) {
                                console.error(`Error deleting item ${itemName}:`, error);
                            }
                        }
                        window.location.reload(); // Refresh page after successful order
                    } else {
                        console.error('Payment failed');
                    }
                },
                prefill: {
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    contact: '9876543210',
                },
                notes: {
                    address: JSON.stringify(address),
                },
                theme: {
                    color: '#2F94A6',
                },
            };

            const razorpay = new window.Razorpay(razorpayOptions);
            razorpay.open(); // Open Razorpay checkout
        }
    };

    const handleIncrement = (productId, currentQuantity) => {
        dispatch(
            addCartItem({
                productId,
                quantity: currentQuantity + 1,
            })
        );
    };

    const handleDecrement = (productId, currentQuantity) => {
        if (currentQuantity > 1) {
            dispatch(
                addCartItem({
                    productId,
                    quantity: currentQuantity - 1,
                })
            );
        }
    };

    return (
        <section className="flex justify-center font-poppins">
            <div className='container px-6 py-12 h-auto min-h-screen'>
                <div className='w-full flex justify-between items-center '>
                    <div className='flex items-center gap-2'>
                        <img src={cartIcon} alt="Cart Icon" />
                        <h2 className='font-semibold text-2xl xl:text-3xl' onClick={() => console.log(items)} >{cart.title}</h2>
                    </div>
                </div>
                <div className='w-full grid grid-cols-1 lg:grid-cols-5 gap-4 py-4 '>
                    <div className='lg:col-span-3 flex flex-col justify-between gap-2'>
                        {items?.items?.length > 0 ? (
                            items.items.map((item, index) => {
                                const discountedPrice = getDiscountedPrice(item?.productId?.variation[0]?.price, item?.productId?.variation[0]?.discount);
                                const itemPrice = discountedPrice * item?.quantity;
                                return (
                                    <CartItems key={index} item={item} itemPrice={itemPrice} decrement={() => handleDecrement(item.productId._id, item.quantity)} increment={() => handleIncrement(item.productId._id, item.quantity)} />
                                );
                            })
                        ) : (
                            cartItemLocal.length === 0 ? <p>Your cart is empty.</p> : cartItemLocal.map((item, index) => (
                                <CartItems key={index} cartItemLocal={item} />
                            ))
                        )}
                    </div>
                    <div className='lg:col-span-2'>
                        <div className='border-[#71717126] border py-1 lg:py-2 rounded-xl'>
                            <div className='border-b border-[#71717126] p-3 text-[#2F94A6]'>{cart.priceDetail}</div>
                            <div className='p-3 w-full'>
                                <div className='border-b border-[#71717126] w-full'>
                                    {items?.items?.length > 0 ? (
                                        items.items.map((item, index) => {
                                            const discountedPrice = getDiscountedPrice(item?.productId?.variation[0]?.price, item?.productId?.variation[0]?.discount);
                                            const itemPrice = discountedPrice * item?.quantity;
                                            return (
                                                <div key={index} className='flex items-center justify-between py-2'>
                                                    <div>{cart.item}{index + 1}</div>
                                                    <div>₹{itemPrice}</div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        cartItemLocal.map((item, index) => (
                                            <div key={index} className='flex items-center justify-between py-2'>
                                                <div>{cart.item}{index + 1}</div>
                                                <div>₹{item.price}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                                <div className='border-b border-[#71717126] w-full'>
                                    <div className='flex items-center justify-between pt-2'>
                                        <div>{cart.total}</div>
                                        <div>₹{totalDiscountedPrice}</div>
                                    </div>
                                    <div className='flex items-center justify-between py-2'>
                                        <div className='flex items-center gap-1'>
                                            {cart.gst}
                                            <img src={ibutton} alt="Info Button" />
                                        </div>
                                        <div>₹{gstAmt}</div>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className='flex items-center justify-between pb-8 py-2'>
                                        <div className='font-semibold uppercase'>{cart.grandTotal}</div>
                                        <div className='text-[#EF2D2D] font-semibold text-sm md:text-base lg:text-lg'>
                                            ₹{grandTotalAmout}
                                        </div>
                                    </div>
                                    <div>
                                        {items?.items?.length !== 0 &&
                                            <button
                                                onClick={handleAddOrder}
                                                className='hover:bg-[#1A444C] border border-[#1A444C] rounded-lg p-2 w-full font-semibold hover:text-white'>
                                                {cart.proceedToPay}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full py-8'>
                    <div>
                        <h2 className='font-semibold text-2xl xl:text-3xl'>{cart.refurbishedProducts.title}</h2>
                    </div>
                    {refurbished.length === 0 ? (
                        <p>No products available at the moment.</p>
                    ) : (
                        <div className="py-4">
                            {refurbished.length > 3 ? (
                                <Slider {...settings} className="relative">
                                    {refurbished.map((product) => (
                                        <CommonBigOffer
                                            key={product._id}
                                            id={product._id}
                                            like={like}
                                            parentCategoryName={product.category.name}
                                            img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                                            name={product.name}
                                            price={product.variation[0].price}
                                            off={`${product.variation[0].discount}% off`}
                                            data={shopByCategory.medicalDeviceArray.thermoMeter}
                                            dataOrg={product}
                                        />
                                    ))}
                                </Slider>
                            ) : (
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                    {refurbished.map((product) => (
                                        <CommonBigOffer
                                            key={product._id}
                                            id={product._id}
                                            like={like}
                                            parentCategoryName={product.category.name}
                                            img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                                            name={product.name}
                                            price={product.variation[0].price}
                                            off={`${product.variation[0].discount}% off`}
                                            data={shopByCategory.medicalDeviceArray.thermoMeter}
                                            dataOrg={product}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Cart;
