import React, { useEffect, useState } from "react";
import CommonBigOffer from "./CommonBigOffer";
import bigOffer from "./bigOfferString";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Slider from "react-slick";
import { fetchBigOfferProducts, fetchProductsAsync } from "../../reduxStore/productSlice";
import { useDispatch, useSelector } from "react-redux";
import like from "./../../assets/like.svg";
import shopByCategory from "../shopByCategory/shopByCategoryString";
import { fetchBigOffer } from "../../apiEndPoint";
import { Link } from "react-router-dom";

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 top-[38%] md:top-[31%] lg:top-[29%] xl:top-[35%] 2xl:top-[36%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Previous"
  >
    <GrLinkPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 top-[38%] md:top-[31%] lg:top-[29%] xl:top-[35%] 2xl:top-[36%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Next"
  >
    <GrLinkNext />
  </button>
);

const MainBigOffer = () => {
  const dispatch = useDispatch();
  const signInId = useSelector((state) => state.modal.signInId);

  // Select the bigOfferItems from the Redux store
  const bigOfferItems = useSelector((state) => state.products.bigOfferItems);
  const loadingBigOfferItems = useSelector((state) => state.products.loadingBigOfferItems);
  const error = useSelector((state) => state.products.error);
  useEffect(() => {
    dispatch(fetchBigOfferProducts());
    console.log(bigOfferItems)
  }, [dispatch]);

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1536, settings: { slidesToShow: 4 } },
      { breakpoint: 1280, settings: { slidesToShow: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <section className="flex justify-center font-poppins">
      <div className="container px-6 py-12">
        <div className="w-full flex justify-between items-center">
          <div>
            <h2 className="font-semibold text-2xl xl:text-3xl" onClick={() => console.log(bigOfferItems)}>
              {bigOffer.title}
            </h2>
            <div className="text-[#848484] text-xs md:text-sm lg:text-base">
              {bigOffer.desc}
            </div>
          </div>
          <div>
            <button className="underline text-[#1A444C] text-xs md:text-base lg:text-lg xl:text-xl">
              <Link to="/bigOfferPage">See All</Link>
            </button>
          </div>
        </div>

        {/* Slick Slider Section */}
        {loadingBigOfferItems ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <div className="py-4">
            {bigOfferItems.length > 3 ? (
              <Slider {...settings} className="relative ">
                {bigOfferItems.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    id={product._id}
                    like={like}
                    parentCategoryName={product?.category?.name}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    offLabel={`Flat ${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {bigOfferItems.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    id={product._id}
                    like={like}
                    parentCategoryName={product?.category?.name}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    offLabel={`Flat ${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))
                }
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default MainBigOffer;
