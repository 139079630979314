import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsAsync } from "./../../reduxStore/productSlice";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import CommonBigOffer from "../bigOffer/CommonBigOffer";
import likeSVG from "./../../assets/like.svg";
import shopByCategory from "../shopByCategory/shopByCategoryString";

const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 top-[38%] md:top-[31%] lg:top-[29%] xl:top-[35%] 2xl:top-[39%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Previous"
  >
    <GrLinkPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 top-[38%] md:top-[31%] lg:top-[29%] xl:top-[35%] 2xl:top-[39%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Next"
  >
    <GrLinkNext />
  </button>
);

const BestSeller = () => {
  const dispatch = useDispatch();
  const {
    items: products,
    loading,
    error,
  } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProductsAsync());
    // console.log(products);
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1536, settings: { slidesToShow: 4 } },
      { breakpoint: 1280, settings: { slidesToShow: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <section className="flex justify-center font-poppins">
      <div className="container px-6 py-12">
        <div className="w-full flex justify-between items-center">
          <div className="w-full">
            <div className="flex items-center justify-between">
              <h2 onClick={() => console.log(products)} className="font-semibold text-2xl xl:text-3xl">
                Best Sellers
              </h2>
              <button className="underline text-[#1A444C] text-xs md:text-base lg:text-lg xl:text-xl">
                <Link to="/bestSellerPage">See All</Link>
              </button>
            </div>
            <div className="text-[#848484] text-xs md:text-sm lg:text-base">
              Our best-selling products
            </div>
          </div>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error loading products: {error}</p>
        ) : (
          <div className="py-4">
            {products.length > 3 ? (
              <Slider {...settings} className="relative ">
                {products.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    id={product._id}
                    like={likeSVG}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    parentCategoryName={product.category.name}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {products.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    id={product._id}
                    like={likeSVG}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    parentCategoryName={product?.category?.name || " "}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))
                }
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default BestSeller;
